<template>
  <div>
    <div
      class="photo"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <input
        v-if="!forContent"
        :id="`fileInput-${unicalId}`"
        type="button"
        class="photo-input"
        :accept="isAccept"
        @click="openStoryCoverAddModal"
      >

      <input
        v-else
        :id="`fileInput-${unicalId}`"
        type="file"
        class="photo-input"
        :accept="isAccept"
        @change="changeInputFiles"
      >
      <label :for="`fileInput-${unicalId}`">
        <component
          :is="isComponent"
          v-if="actualImagePreview"
          :key="modifiedStory.unicId || unicFileId"
          :story="modifiedStory"
          :source="modifiedStory.source"
          :source-type="modifiedStory.sourceType"
          :is-mobile="false"
          :muted="true"
          :active="forContent"
          :loop="true"
          class="design-component"
        />
        <StoryBackgroundForImage
          v-else
          :image-height="isBackgroundHeight"
          :image-width="isBackgroundWidth"
          class="background-image"
          :class="{'error': !imagePreview || !photoUrlProp}"
        />
        <b-button
          v-show="isHovered && actualImagePreview && forContent"
          :type="$const.PRIMARY_BUTTON"
          class="overlay-button"
          @click="showImage"
        >
          <b-icon
            icon="eye"
            style="width: 30px; height: 30px;"
          />
        </b-button>
      </label>
    </div>
    <StoryFilePreview
      v-if="showFilePreview"
      :show="showFilePreview"
      :story="modifiedStory"
      :is-mobile="false"
      @hide-preview="hideImagePreview"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import StoryFilePreview from '@/components/Story/StoryFilePreview';
import StoryBackgroundForImage from '@/components/Story/StoryBackgroundForImage';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';
import { VStoryCover, VStoryViewerCover } from '@doctis.front/doctis.designsystem';

export default {
  name: 'StoryPhotoEditor',
  components: {
    StoryFilePreview,
    StoryBackgroundForImage,
    VStoryCover,
    VStoryViewerCover,
  },
  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    photoUrlProp: {
      type: String,
      default: '',
    },
    previewUrlProp: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: Number,
      default: 100,
    },
    imageWidth: {
      type: Number,
      default: 100,
    },
    forContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imagePreview: null,
      unicalId: uuidv4(),
      unicFileId: null,
      showFilePreview: false,
      isHovered: false,
    };
  },
  computed: {
    actualImagePreview() {
      return this.previewUrlProp || this.photoUrlProp;
    },
    isComponent() {
      return this.forContent ? 'VStoryViewerCover' : 'VStoryCover';
    },
    isBackgroundWidth() {
      return this.forContent ? 150 : 100;
    },
    isBackgroundHeight() {
      return this.forContent ? 300 : 100;
    },
    isAccept() {
      return this.forContent ? 'image/*, video/*' : 'image/*';
    },
    modifiedStory() {
      const objectForStoryCover = {
        title: this.story.title,
        image: this.actualImagePreview,
        isHighShowPriority: false,
      };
      const objectForVStoryViewerCover = {
        ...this.story,
        source: this.actualImagePreview,
        isHighShowPriority: false,
        unicId: uuidv4(),
        sourceType: this.story.contentFileContentType,
      };
      return this.forContent ? objectForVStoryViewerCover : objectForStoryCover;
    },
  },
  mounted() {
    this.unicFileId = uuidv4();
  },
  methods: {
    async changeInputFiles(event) {
      if (event) {
        this.isHovered = false;
      }
      const file = event.target.files[0];

      const fileType = file?.type.split('/')[0];
      this.story.contentFileContentType = fileType;

      await this.readAndUploadFile(file);
    },
    async readAndUploadFile(file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.$emit('setPreview', reader.result);
      };

      this.$emit('upload', file);
    },
    showImage() {
      if (this.actualImagePreview) { this.showFilePreview = true; }
    },
    hideImagePreview(value) {
      this.showFilePreview = value;
    },
    openStoryCoverAddModal() {
      uiService.showModal(MODALS.STORY_COVER_ADD_MODAL, {
        name: 'StoryCoverAddModal',
        props: {
          fileUrl: this.actualImagePreview,
        },
        actions: {
          saveFile: async (file) => {
            await this.readAndUploadFile(file);
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.photo {
  position: relative;
  display: inline-block;
  &-input {
    display: none;
    height: 100px;
  }
  .image {
  cursor: pointer;
}
  .image:hover {
    filter: brightness(80%);
    transition: filter 0.3s ease;
  }
  .background-image{
    cursor: pointer;
  }

  .overlay-button {
    position: absolute;
    border: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 5px 10px;
    opacity: 0.6;
  }
  .error{
    outline: 1px dashed red;
  }
}
.design-component{
  width: 150px;
  height: 300px;
}
</style>
