<template>
  <div class="btn-options-container">
    <div
      v-for="(button, idx) in buttons"
      :key="`btn-${idx}`"
      class="btn-fields"
    >
      <div class="mt-2 mb-2">
        <div class="mb-2 h6">
          Текст кнопки
        </div>
        <b-input
          v-model="button.text"
          placeholder="Текст кнопки"
          :state=" v.storyСarousel.$each[index].buttons.$each[idx].text"
          :disabled="button.type === 'MoveToNextCarouselItemAction'"
        />
      </div>
      <div class="mb-2">
        <div class="mb-2 h6">
          Стиль кнопки
        </div>
        <v-select
          v-model="button.style"
          :reduce="style => style.name"
          :options="BUTTON_STYLES"
          placeholder="Стиль"
          label="name"
          class="crm-select"
          :class="{'error': checkedStoryСarouselErrors(index, idx, 'style')}"
        />
      </div>
      <div class="mb-2">
        <div class="mb-2 h6">
          Тип кнопки
        </div>
        <v-select
          v-model="button.type"
          :reduce="type => type.value"
          :options="filteredButtonsType"
          placeholder="Тип"
          label="name"
          class="crm-select"
          :class="{'error': checkedStoryСarouselErrors(index, idx, 'type')}"
          @input="changeButtonType($event, idx)"
        />
      </div>
      <div v-if="button.type === 'Link'">
        <div class="mb-2 h6">
          Ссылка
        </div>
        <b-form-input
          v-model="button.value"
          placeholder="Название ссылки"
          :state="v.storyСarousel.$each[index].buttons.$each[idx].value"
        />
      </div>
      <div
        class="btn-content"
      >
        <b-button
          v-if="buttons.length > 1"
          variant="outline-danger"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="mt-3"
          :disabled="buttons.length <= 1"
          @click="$emit('removeContentButtons', idx)"
        >
          Удалить
        </b-button>
      </div>
    </div>

    <b-button
      v-if="buttons.length < 2"
      variant="primary"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="add-button-btn"
      @click="$emit('addContentButtons')"
    >
      Добавить кнопку
    </b-button>
  </div>
</template>

<script>

const DEFAULT_TEXT_BUTTON = 'Следующий слайд';

export default {
  name: 'StoryButtonSettings',
  props: {
    buttons: {
      type: Array,
      default: () => ([]),
    },
    index: {
      type: [Number, String],
      default: null,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      BUTTON_STYLES: [
        { name: 'Primary' },
        { name: 'Outline' },
        { name: 'Light' },
        { name: 'Ghost' },
      ],
      BUTTON_TYPES: [
        {
          name: 'Ссылка',
          value: 'Link',
        },
        {
          name: 'След. слайд',
          value: 'MoveToNextCarouselItemAction',
        },
      ],
    };
  },
  computed: {
    filteredButtonsType() {
      return this.carousel.length - 1 === this.index ? this.BUTTON_TYPES.filter((item) => item.value === 'Link') : this.BUTTON_TYPES;
    },
  },
  methods: {
    checkedStoryСarouselErrors(index, idx, param) {
      return !this.v.storyСarousel.$each[index].buttons.$each[idx][param];
    },
    changeButtonType(event, idx) {
      if (event === 'MoveToNextCarouselItemAction') {
        this.buttons[idx].text = DEFAULT_TEXT_BUTTON;
      } else {
        this.buttons[idx].text = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
  }

  .vs__dropdown-menu {
    max-height: 150px;
  }

  .vs__search {
    background: $white;
  }
}
.btn-options-container {
 display: flex;
 gap: 10px;
 flex-direction: column;
 align-items: center;
  .btn-fields {
    width: 200px;
    max-height: 400px;
    padding: 5px;
    margin: 1px;
    background-color: rgb(228, 236, 232);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
  }
}

.btn-content{
  margin: 5px;
  text-align: center;
}
.error {
 outline-color: red;
}
.add-button-btn {
  margin: 15px 0;
}
</style>
