<template>
  <div class="settings-container p-3">
    <div class="d-flex flex-column">
      <div class="mb-3">
        <h4 class="title mb-4">
          Настройки показа
        </h4>
        <div class="mb-4">
          <div class="mb-2 h6">
            Длительность показа (в днях)
          </div>
          <b-input
            v-model.number="story.showDurationInDays"
            :state="v.$anyError ? !v.story.showDurationInDays.$error : null"
            type="number"
            min="0"
            placeholder="Кол-во дней"
            style="width: 250px;"
          />
        </div>

        <div class="d-flex">
          <b-form-checkbox
            id="checkbox-1"
            v-model="story.isDisplayAtStartup"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            <span class="crm-form-checkbox-text">
              Показывать при запуске приложения
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-2"
            v-model="story.isHighShowPriority"
            name="checkbox-2"
            :value="true"
            class="ml-4"
            :unchecked-value="false"
            :disabled="!story.eventBinding"
          >
            <span class="crm-form-checkbox-text">
              Высокий приоритет(закрепить в карусели)
            </span>
          </b-form-checkbox>
        </div>
        <div class="d-flex justify-content-between mt-5">
          <div class="mb-2 h6">
            Привязка к событию
          </div>
          <b-form-checkbox
            v-model="story.eventBinding"
            switch
            :disabled="!!storyId"
            @change="$emit('onEventBindingChange',{value: $event})"
          />
        </div>
      </div>
      <div v-if="story.eventBinding">
        <div
          v-for="(event, index) in events"
          :key="`event-${index}`"
        >
          <StoryEventsSettings
            :index="index"
            :event="event"
            :story-id="storyId"
            :dictionary-events="dictionaryEvents"
            :disabled-remove-btn="events.length === 1"
            :v="v"
            @addCondition="$emit('addCondition', {eventIndex: index})"
            @removeEvent="$emit('removeEvent', index)"
            @changeType="$emit('changeEventType', {eventIndex: index, value: $event})"
          />
        </div>
      </div>
      <b-button
        v-if="story.eventBinding && events?.length < 5"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="mt-2 mr-auto"
        @click="$emit('storyEventAdd')"
      >
        Добавить событие
      </b-button>
    </div>
  </div>
</template>

<script>
import StoryEventsSettings from './StoryEventsSettings';

export default {
  name: 'StorySettings',
  components: {
    StoryEventsSettings,
  },

  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    events: {
      type: Array,
      default: () => ([]),
    },
    dictionaryEvents: {
      type: Array,
      default: () => ([]),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      eventBinding: false,
    };
  },
  watch: {
    'story.eventBinding': {
      handler(event) {
        if (!event && !this.storyId) {
          this.story.isHighShowPriority = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-container {
  width: 100%;
  min-height: 45vh;
  background-color: #fff;
  overflow-y: auto;
}
.crm-form-checkbox-text {
  font-size: 14px;
  line-height: 18px;
  vertical-align: sub;
}
</style>
