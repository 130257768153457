import { render, staticRenderFns } from "./StoryEventsSettings.vue?vue&type=template&id=2a0bd457&scoped=true"
import script from "./StoryEventsSettings.vue?vue&type=script&lang=js"
export * from "./StoryEventsSettings.vue?vue&type=script&lang=js"
import style0 from "./StoryEventsSettings.vue?vue&type=style&index=0&id=2a0bd457&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0bd457",
  null
  
)

export default component.exports