<template>
  <div class="events-wrapper">
    <div class="mb-3 h6">
      Событие {{ Number(index) + 1 }}
    </div>
    <div class="mr-5">
      <div class="mb-2 h6">
        Тип события
      </div>
      <v-select
        v-model="event.event"
        :reduce="event => event.event"
        :options="dictionaryEvents"
        placeholder="Тип события"
        label="title"
        class="crm-select event"
        :class="{'error': v.storyEvents.$each[index].event.$error}"
        @input="changeEvent"
      />
    </div>
    <div
      v-for="(condition, idx) in event.conditions"
      :key="`condition-${idx}`"
    >
      <div v-if="filteredDictionaryEvents?.parameters.length != 0 " class="events-wrapper-options mt-2">
        <div>
          <div class="mb-2 h6">
            Параметр
          </div>
          <v-select
            v-model="condition.parameter"
            :options="filteredDictionaryEvents?.parameters"
            placeholder="Параметр"
            label="parameter"
            :disabled="!event.event"
            :class="{'error': checkedConditionsErrors(index, idx, 'parameter')}"
            class="crm-select condition"
            @input="changeParameter($event, idx)"
          />
        </div>
        <div>
          <div class="mb-2 h6">
            Сравнение
          </div>
          <v-select
            v-model="condition.comparisonOperator"
            :reduce="comparisonOperator => comparisonOperator.value"
            :options="comparisonOperatorsArray(condition?.parameter?.dataType)"
            :class="{'error': checkedConditionsErrors(index, idx, 'comparisonOperator')}"
            placeholder="Сравнение"
            label="name"
            :disabled="!condition?.parameter"
            class="crm-select condition"
            @input="changeComparisonOperator($event, idx)"
          />
        </div>
        <div>
          <div class="mb-2 h6">
            Значение
          </div>
          <b-input
            :key="condition.uniqId || condition.id"
            v-model="condition.value"
            :type="conditionValueType(condition.parameter)"
            :state="checkedInputValue(index, idx)"
            :min="conditionValueType(condition.parameter) === 'number'? 0 : ''"
            placeholder="Значение"
            :disabled="!condition.parameter || !condition.comparisonOperator"
          />
        </div>
        <div
          class="btn-content"
        >
          <b-button
            variant="outline-danger"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="border border-danger"
            :disabled="event.conditions.length <= 1"
            @click="removeCondition(idx)"
          >
            <b-icon icon="x" />
          </b-button>
        </div>
      </div>
    </div>
    <div
      class="d-flex mt-3"
    >
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        :disabled="disabledRemoveBtn"
        @click="$emit('removeEvent')"
      >
        Удалить событие
      </b-button>
      <b-button
        v-if="event.conditions?.length < 5 && filteredDictionaryEvents?.parameters.length != 0"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        size="sm"
        class="ml-2"
        @click="$emit('addCondition')"
      >
        Добавить параметр
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  COMPARSION_OPERATORS_FOR_NUMERIC,
  COMPARSION_OPERATORS_FOR_STRING,
} from '@/helpers/consts';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'StoryEventsSettings',
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    dictionaryEvents: {
      type: Array,
      default: () => ([]),
    },
    index: {
      type: Number,
      default: null,
    },
    disabledRemoveBtn: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      COMPARSION_OPERATORS_FOR_NUMERIC,
      COMPARSION_OPERATORS_FOR_STRING,
    };
  },
  computed: {
    filteredDictionaryEvents() {
      return this.dictionaryEvents.find((item) => item.event === this.event.event);
    },
  },
  methods: {
    checkedConditionsErrors(index, idx, param) {
      return this.v.storyEvents.$each[index].conditions.$each[idx][param].$error;
    },
    checkedInputValue(index, idx) {
      return this.v.storyEvents.$each[index].conditions.$each[idx]
        .value.$anyError ? !this.v.storyEvents.$each[index]
          .conditions.$each[idx].value.$error : null;
    },
    removeCondition(index) {
      this.event.conditions.splice(index, 1);
    },
    changeEvent(e) {
      this.$emit('changeType', e);
    },
    changeParameter(e, index) {
      const actualCondition = this.event.conditions[index];
      actualCondition.comparisonOperator = null;
      actualCondition.value = null;
      actualCondition.uniqId = uuidv4();
    },
    changeComparisonOperator(e, index) {
      const actualCondition = this.event.conditions[index];
      actualCondition.value = null;
      actualCondition.uniqId = uuidv4();
    },
    comparisonOperatorsArray(value) {
      return value === 'Numeric' ? this.COMPARSION_OPERATORS_FOR_NUMERIC
        : this.COMPARSION_OPERATORS_FOR_STRING;
    },
    conditionValueType(value) {
      switch (value?.dataType) {
        case 'Numeric':
          return 'number';
        case 'String':
          return 'text';
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
  }

  .vs__search {
    background: $white;
  }
}
.events-wrapper{
  width: 100%;
  min-height: 250px;
  padding: 15px;
  margin-bottom: 20px;
  background: rgb(243, 241, 241);
  box-shadow: 5px 5px 5px -5px rgba(0,0,0,0.2);
    &-options {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 36px;;
      align-items: end;
      gap: 15px;
    }
    .event {
      width: 100%;
    }
}
.btn-content{
  width: 36px;
  margin: 0 5px 3px auto;
}
.error {
 outline-color: red;
}
</style>
